.spinner-inspections {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    position: relative;
}

.spinner-outer-circle {
    width: 100%;
    height: 100%;
    border: 4px solid #E2E8F0;
    border-radius: 50%;
}

.spinner-inner-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-top: 4px solid #2563EB;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} 