@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
.map {
    height: 100%;
    width: 100%;
}

.infowindow-text {
    font-family: 'Varela Round', sans-serif;
    margin: auto;
}

/* Popup Styles */
.address-popup-container {
    padding: 8px;
    min-width: 280px;
    max-width: 320px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.address-popup {
    font-size: 14px;
    color: #2c3e50;
    margin: 0 0 16px 0;
    line-height: 1.4;
    font-weight: 500;
}

.buttons-container {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.action-button {
    flex: 1;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.move-button {
    background-color: #f8f9fa;
    color: #2c3e50;
    border: 1px solid #e9ecef;
}

.move-button:hover {
    background-color: #e9ecef;
}

.go-button {
    background-color: #00be95;
    color: white;
    border: none;
}

.go-button:hover {
    background-color: #00a380;
}

.driver-list {
    margin-top: 16px;
    border-top: 1px solid #e9ecef;
    padding-top: 16px;
}

.move-to-text {
    font-size: 12px;
    color: #6c757d;
    margin: 0 0 12px 0;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.driver-button {
    padding: 10px 16px;
    margin-bottom: 8px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: white;
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
}

.driver-button:hover {
    opacity: 0.9;
}

/* Add material icons */
.material-icons {
    font-size: 16px;
}

#big-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
#big-loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00a380;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
#big-loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00b28b;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
#big-loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00be95;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}